// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-a-viewing-js": () => import("./../../../src/pages/book-a-viewing.js" /* webpackChunkName: "component---src-pages-book-a-viewing-js" */),
  "component---src-pages-make-an-offer-js": () => import("./../../../src/pages/make-an-offer.js" /* webpackChunkName: "component---src-pages-make-an-offer-js" */),
  "component---src-pages-market-property-market-property-js": () => import("./../../../src/pages/MarketProperty/MarketProperty.js" /* webpackChunkName: "component---src-pages-market-property-market-property-js" */),
  "component---src-pages-property-details-property-details-js": () => import("./../../../src/pages/PropertyDetails/PropertyDetails.js" /* webpackChunkName: "component---src-pages-property-details-property-details-js" */),
  "component---src-pages-property-for-sale-index-js": () => import("./../../../src/pages/property/for-sale/index.js" /* webpackChunkName: "component---src-pages-property-for-sale-index-js" */),
  "component---src-pages-property-to-rent-index-js": () => import("./../../../src/pages/property/to-rent/index.js" /* webpackChunkName: "component---src-pages-property-to-rent-index-js" */),
  "component---src-pages-property-valuation-book-a-valuation-js": () => import("./../../../src/pages/property-valuation/book-a-valuation.js" /* webpackChunkName: "component---src-pages-property-valuation-book-a-valuation-js" */),
  "component---src-pages-property-valuation-index-js": () => import("./../../../src/pages/property-valuation/index.js" /* webpackChunkName: "component---src-pages-property-valuation-index-js" */),
  "component---src-pages-search-result-search-result-js": () => import("./../../../src/pages/SearchResult/SearchResult.js" /* webpackChunkName: "component---src-pages-search-result-search-result-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-area-guide-details-js": () => import("./../../../src/templates/AreaGuideDetails.js" /* webpackChunkName: "component---src-templates-area-guide-details-js" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/BlogDetails.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-our-team-js": () => import("./../../../src/templates/OurTeam.js" /* webpackChunkName: "component---src-templates-our-team-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/property-details.js" /* webpackChunkName: "component---src-templates-property-details-js" */)
}

